<template>
    <v-layout > 
        <!-- //--------------------MENU -->
        <v-layout v-if="show"> 
            <v-menu v-if="item_order"
                v-model="menu_update_order_option" 
                :close-on-content-click="false" 
                offset-y  offset-overflow
                :nudge-width="90" rounded="xl">

                <template v-slot:activator="{ on }">   
                    <v-card v-on="on"  height="" width="100%" max-height="55" min-height="45"
                        :disabled="disabled || inputLoading" 
                        :flat="disabled" 
                        hover  
                        class=" "
                        :class="vs.mdAndDown?'':''" 
                        :color="disabled?'grey darken-1':'secondary'" dark >
                        <v-layout fill-height class="ma-0" justify-center align-center v-if="!inputLoading"> 
                            <v-spacer/> 
                                <div class="font-weight-bold  " :class="dense?'not-f3 pl-1':'not-f5'">Update</div>
                            <v-spacer/>
                            <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                        </v-layout> 
                        <v-layout v-else class="ma-0 pa-2" justify-center>
                            <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                        </v-layout>
                    </v-card>  
                </template>  
                <v-card>
                    <v-card-title primary-title class="secondary white--text">
                        Select Update Option
                    </v-card-title>
                    <v-list class="pb-3">
                        <v-list-item  @click="ON_UPDATE_ORDER_OPTION({option:'update'})">
                            Update Order
                        </v-list-item>
                        <v-list-item v-if="CurrentOutlet?.outlet_type_code=='restaurant'"  
                            @click="ON_UPDATE_ORDER_OPTION({option:'update_print_docket'})">
                            Update & Print Docket
                        </v-list-item>
                        <v-list-item @click="ON_UPDATE_ORDER_OPTION({option:'update_bill'})">
                            Update & Print Bill
                        </v-list-item>
                    </v-list>
                    <v-divider/>
                    <v-card-actions class="pa-0 ma-0">
                        <v-btn text width="100%" color="secondary" @click="ON_UPDATE_ORDER_OPTION({option:'update_cancel'})">Cancel Update</v-btn> 
                    </v-card-actions> 
                </v-card>
            </v-menu>
            <v-menu v-else-if="MBS.actions.SIZE(ItemOrderOptionsData)>0"
                v-model="menu_select_order_option" 
                :close-on-content-click="false" 
                offset-y  offset-overflow
                :nudge-width="90" rounded="xl">

                <template v-slot:activator="{ on }">   
                    <v-card v-on="on"  height="" width="100%" max-height="55" min-height="45"
                        :disabled="disabled || inputLoading" 
                        :flat="disabled" 
                        hover  
                        class=" "
                        :class="vs.mdAndDown?'':''" 
                        :color="disabled?'grey darken-1':'secondary'" dark >
                        <v-layout fill-height class="ma-0" justify-center align-center v-if="!inputLoading"> 
                            <v-spacer/> 
                                <div class="font-weight-bold  " :class="dense?'not-f3 pl-1':'not-f5'">Order Option</div>
                            <v-spacer/>
                            <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                        </v-layout> 
                        <v-layout v-else class="ma-0 pa-2" justify-center>
                            <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                        </v-layout>
                    </v-card>  
                </template>  
                <v-card>
                    <v-card-title primary-title class="secondary white--text">
                        Select Order Option
                    </v-card-title>
                    <v-list class="pb-3">
                        <v-list-item v-if="CurrentOutlet?.outlet_type_code=='restaurant'"  @click="SELECT_ORDER_OPTION({name:'Send Order',key:'send_order',code:'send_order'})">
                            Send New Order
                        </v-list-item>
                        <v-list-item v-if="CurrentOutlet?.outlet_type_code=='restaurant'"  @click="SELECT_ORDER_OPTION({name:'Update Order',key:'update_order',code:'update_order'})">
                            Update Exiting Order
                        </v-list-item>
                        <v-divider/>
                        <v-list-item v-for="(order_option, index) in ActionAndProcessingOrderOption" :key="index" 
                            @click="SELECT_ORDER_OPTION(order_option)">
                            {{order_option.name}}
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>   
        </v-layout> 

        <!-- //---------------------DIALOG -->
        <div>  
            <!-- KITCHEN NEW ORDER OPTIONS -->
            <v-dialog
                v-model="dialog_new_order_option"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="dialog_new_order_option"> 
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">ORDER OPTION</div> 
                        <v-spacer></v-spacer>
                        <!-- <div class="font-weight-bold b1--text not-f7">mwk{{MBS.actions.money(TotalDiscountedPrice)}}</div> -->
                    </v-card-title>
                    <v-card-text>
                        <br> 
                        <div class="mb-2">Order details  </div>
                        <form @submit.prevent="CREATE_NEW_ORDER(input,SELECTED_ORDER_OPTION)"> 
                            <mbs-input-fields class="mx-3"
                                v-model="input"
                                :outlined="true"
                                :dense="false"
                                py="1" px="1"
                                :field_items="DATA.FIELDS.send_order_options"
                                :select_items="DataSource"  
                                />  
                            <v-layout row wrap class="ma-0 " >
                                <!-- <v-flex xs6>
                                    <div class="mb-2">Attach a Customer</div>
                                    <v-autocomplete  
                                        filled outlined autofocus 
                                        prepend-inner-icon="mdi-account" 
                                        :items="Customers" 
                                        item-text="name"
                                        item-value="key" 
                                        v-model="input.customer_key"
                                        class="mr-1"
                                    />  
                                </v-flex>
                                <v-flex xs6>
                                    <div class="mb-2">Location</div>
                                    <v-text-field  prepend-inner-icon="mdi-map" 
                                        v-model="input.location_name"
                                        filled outlined   clearable
                                        class="m-1"
                                    ></v-text-field> 
                                </v-flex>
                                <v-flex xs12>
                                    <div class="mb-2">Description</div> 
                                    <v-textarea  
                                        v-model="input.description"
                                        filled outlined 
                                        class="m-1"
                                    ></v-textarea> 
                                </v-flex> -->
                                <v-flex sm8>
                                    <v-btn type="submit" large  
                                        @click="PUT_AND_PRINT = false"
                                        :loading="inputLoading"
                                        class="mt-3 "  color="primary" height="60" width="99%">
                                        <div class="not-f5">{{ SELECTED_ORDER_OPTION?SELECTED_ORDER_OPTION.name:'..' }}</div>  
                                    </v-btn>   
                                </v-flex> 
                                <v-flex sm4>
                                    <v-btn type="submit" large  
                                        :loading="inputLoading"
                                        @click="PUT_AND_PRINT = true"
                                        class="mt-3 "  color="secondary" height="60" width="99%">
                                        <div class="not-f5">Send & Print</div>  
                                    </v-btn>   
                                </v-flex> 
                            </v-layout>  
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-dialog>  
            
            <!-- KITCHEN UPDATE ORDER OPTIONS -->
            <v-dialog
                v-model="dialog_update_order_option"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="dialog_update_order_option"> 
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">UPDATE ORDER OPTION</div> 
                        <v-spacer></v-spacer>
                        <v-btn @click="dialog_update_order_option=false" dark icon><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <br> 
                        <div class="mb-2">Order details  </div>
                        <form @submit.prevent="UPDATE_EXISTING_ORDER(input,SELECTED_ORDER_OPTION,ORDER_PRINT_OPTIONS)"> 
                            <mbs-input-fields class="mx-3"
                                v-model="input"
                                :outlined="true"
                                :dense="false"
                                py="1" px="1"
                                :field_items="DATA.FIELDS.update_order_options"
                                :select_items="DataSource"  
                                />  
                            <v-layout row wrap class="ma-0 " >
                                
                                <v-flex sm8>
                                    <v-btn type="submit" large  
                                        @click="PUT_AND_PRINT = false"
                                        :loading="inputLoading"
                                        class="mt-3 "  color="primary" height="60" width="99%">
                                        <div class="not-f5">{{ SELECTED_ORDER_OPTION?SELECTED_ORDER_OPTION.name:'..' }}</div>  
                                    </v-btn>   
                                </v-flex> 
                                <v-flex sm4>
                                    <v-btn type="submit"  large  :v-show="false"
                                        :loading="inputLoading" v-show="false"  ref="ref_btn_update_print"
                                        class="mt-3 "  color="secondary" height="60" width="99%">
                                        <div class="not-f5">Update & Print</div>   
                                    </v-btn>   
                                    <v-menu  
                                        v-model="menu_update_existing_order_option" 
                                        :close-on-content-click="false" 
                                        offset-y  offset-overflow
                                        :nudge-width="90" rounded="xl"> 
                                        <template v-slot:activator="{ on }">    
                                            <v-btn v-on="on"  large  
                                                :loading="inputLoading" 
                                                class="mt-3 "  color="secondary" height="60" width="99%">
                                                <div class="not-f5">Update & Print</div>  
                                                <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                                            </v-btn>   

                                            <!-- <v-card v-on="on"  height="" width="100%" max-height="55" min-height="45"
                                                :disabled="disabled || inputLoading" 
                                                :flat="disabled" 
                                                hover  
                                                class=" "
                                                :class="vs.mdAndDown?'':''" 
                                                :color="disabled?'grey darken-1':'secondary'" dark >
                                                <v-layout fill-height class="ma-0" justify-center align-center v-if="!inputLoading"> 
                                                    <v-spacer/> 
                                                        <div class="font-weight-bold  " :class="dense?'not-f3 pl-1':'not-f5'">Update</div>
                                                    <v-spacer/>
                                                    <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                                                </v-layout> 
                                                <v-layout v-else class="ma-0 pa-2" justify-center>
                                                    <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                                                </v-layout>
                                            </v-card>   -->
                                        </template>  
                                        <v-card>
                                            <v-card-title primary-title class="secondary white--text">
                                                Select Update Option
                                            </v-card-title>
                                            <v-list class="pb-3">
                                                <v-list-item  @click="ON_UPDATE_EXISTING_ORDER_OPTION({option:'update'})">
                                                    Update Order
                                                </v-list-item>
                                                <v-list-item v-if="CurrentOutlet?.outlet_type_code=='restaurant'"  
                                                    @click="ON_UPDATE_EXISTING_ORDER_OPTION({option:'update_print_docket'})">
                                                    Update & Print Docket
                                                </v-list-item>
                                                <v-list-item @click="ON_UPDATE_EXISTING_ORDER_OPTION({option:'update_bill'})">
                                                    Update & Print Bill
                                                </v-list-item>
                                            </v-list>
                                            <v-divider/>
                                            <v-card-actions class="pa-0 ma-0">
                                                <v-btn text width="100%" color="secondary" @click="ON_UPDATE_ORDER_OPTION({option:'update_cancel'})">Cancel Update</v-btn> 
                                            </v-card-actions> 
                                        </v-card>
                                    </v-menu>
                                </v-flex> 
                            </v-layout>  
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-dialog>  

            <!-- View ORDER  DIALOG -->
            <v-dialog
                v-model="dialog_view_order"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="700px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="dialog_view_order" > 
                    <!-- //----Header----- -->
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text"> 
                            <span >BILL ORDER:</span> 
                            {{SELECTED_ORDER.key}}
                        </div> 
                        <v-spacer></v-spacer> 
                        <v-btn class="ml-9" @click="dialog_view_order=false" icon dark><v-icon >close</v-icon></v-btn>
                    </v-card-title>    

                    <!-- //-----Body----------------- -->
                    <v-card-text class="b1">
                        <!-- -----------receipt short info-------- -->
                        <br>
                        <v-card outlined>
                            <v-card-text> 
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-receipt</v-icon>
                                    <div>Customer:</div>
                                    <div class="ml-2 font-weight-bold">{{ SELECTED_ORDER.customer_name }}</div>
                                    <v-spacer/>
                                    <div>Table:</div>
                                    <div class="ml-2 font-weight-bold">{{ SELECTED_ORDER.table_name }}</div>
                                </v-layout>
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-receipt</v-icon>
                                    <div>Total Amount:</div>
                                    <v-spacer/>
                                    <span class="mt-2">MWK</span><div class="ml-1 font-weight-bold not-f7">{{ SELECTED_ORDER.total_sold_price_ }}</div>
                                </v-layout>
                            </v-card-text> 
                        </v-card>

                        <!-- -------Item list--------- -->
                        <v-card outlined class="mt-2">
                            <!-- //list -->
                            <v-list>
                                <v-list-item @click="
                                        SELECTED_ORDER.expand_items=!SELECTED_ORDER.expand_items 
                                        if (SELECTED_ORDER.expand_details) {
                                            SELECTED_ORDER.expand_details = false
                                        }
                                    ">
                                    <v-list-item-content><div class="font-weight-bold ">{{MBS.actions.SIZE(SELECTED_ORDER.joined_items)}} Item(s)</div></v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn  icon>
                                            <v-icon v-if="SELECTED_ORDER.expand_items">mdi-chevron-up</v-icon>
                                            <v-icon v-else>mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list> 
                            <!-- //list expand  -->
                            <div class="mx-2 mb-2" v-if="SELECTED_ORDER.expand_items"> 
                                <v-simple-table  
                                    fixed-header
                                    :min-height="vsh-590"
                                    >
                                    <template v-slot:default>
                                        <!-- header -->
                                        <thead>
                                            <tr class=" table-background ">
                                                <th class="text-left with-divide font-weight-bold">
                                                    Name
                                                </th>
                                                <th class="text-left with-divide  ">
                                                    price
                                                </th>
                                                <th class="text-left with-divide  ">
                                                    Quantity
                                                </th>
                                                <th class="text-left with-divide ">
                                                    Total Price
                                                </th>
                                            </tr>
                                        </thead>
                                        <!-- body -->
                                        <tbody>
                                            <!-- ------------listing items by department------------- -->
                                            <template  v-if="MBS.actions.SIZE(SELECTED_ORDER.joined_items_departments)"> 
                                                <template v-for="(department,index) in SELECTED_ORDER.joined_items_departments" >
                                                    <v-card class="primary lighten-3 font-weight-bold" dark :key="'_'+index"> 
                                                        <v-layout class="justify-center py-1">
                                                            {{ department.name }} 
                                                        </v-layout>
                                                    </v-card>
                                                    <tr v-for="(item,index2) in department.joined_items" class="b1" >
                                                        <td class="with-divider ">
                                                            {{ item.name }}
                                                            <span v-if="item.selected_item_option_names">
                                                                ({{ item.selected_item_option_names }})
                                                            </span>
                                                        </td>
                                                        <td class="with-divider">{{ MBS.actions.money(item.sold_price) }}</td>
                                                        <td class="with-divider">{{ item.quantity }}</td>
                                                        <td class="with-">{{ MBS.actions.money(item.quantity*item.sold_price) }}</td>
                                                    </tr> 
                                                </template>
                                            </template>
                                            <template v-else>
                                                <tr v-for="item in SELECTED_ORDER.joined_items"  >
                                                    <td class="with-divider ">
                                                        {{ item.name }}
                                                        <span v-if="item.selected_item_option_names">
                                                            ({{ item.selected_item_option_names}})
                                                        </span>
                                                    </td>
                                                    <td class="with-divider">{{ MBS.actions.money(item.sold_price) }}</td>
                                                    <td class="with-divider">{{ item.quantity }}</td>
                                                    <td class="with-">{{ MBS.actions.money(item.quantity*item.sold_price) }}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </template>
                                </v-simple-table> 
                            </div>
                        </v-card>

                        <v-card class="pa-2 mt-2" outlined>
                            <v-list>
                                <v-list-item @click="
                                            SELECTED_ORDER.expand_details=!SELECTED_ORDER.expand_details 
                                            if (SELECTED_ORDER.expand_items) {
                                                SELECTED_ORDER.expand_items=false
                                            }
                                        ">
                                    <v-list-item-content><div class="font-weight-bold">Order Details</div></v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn  icon>
                                            <v-icon v-if="SELECTED_ORDER.expand_details">mdi-chevron-up</v-icon>
                                            <v-icon v-else>mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list> 
                            <div v-if="SELECTED_ORDER.expand_details"> 
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-receipt</v-icon>
                                    <div>Order Number:</div>
                                    <div class="ml-2">{{ SELECTED_ORDER.key }}</div>
                                </v-layout>
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-receipt</v-icon>
                                    <div>Table:</div>
                                    <div class="ml-2">{{ SELECTED_ORDER.table_name }}</div>
                                </v-layout>
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-receipt</v-icon>
                                    <div>Waiter:</div>
                                    <div class="ml-2">{{ SELECTED_ORDER.waiter_name }}</div>
                                </v-layout>
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-cash-register</v-icon>
                                    <div>Initiated By:</div>
                                    <div class="ml-2">{{ SELECTED_ORDER.teller_name }}</div>
                                </v-layout>
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-clipboard-text-clock-outline</v-icon>
                                    <div>Initiated Time:</div>
                                    <div class="ml-2">{{ SELECTED_ORDER.created_at_ }}</div>
                                </v-layout>
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-timer-settings-outline</v-icon>
                                    <div>Estimated Time:</div>
                                    <div class="ml-2">{{ SELECTED_ORDER.estimated_time?SELECTED_ORDER.estimated_time:"..." }}</div>
                                </v-layout>
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-list-status</v-icon>
                                    <div>Status:</div>
                                    <div class="ml-2">{{ SELECTED_ORDER.estimated_time }}</div>
                                </v-layout>
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">home</v-icon>
                                    <div>Description:</div>
                                    <div class="ml-2">{{ SELECTED_ORDER.description }}</div>
                                </v-layout> 
                            </div>
                        </v-card>
                    </v-card-text>  

                    <!-- //-----Action Buttons--------- -->
                    <v-divider></v-divider>
                    <v-card-actions >
                        <v-layout class="ma-0 pa-1" row wrap justify-center align-center> 
                            <v-btn-toggle  dark  class="mb-2" >
                                <v-btn  @click="EXIT_DIALOG()"  color="primary" value="exit">
                                    <span class="hidden-sm-and-down mx-3">EXIT</span>
                                    <v-icon>mdi-exit-to-app</v-icon> 
                                </v-btn>
                                
                                <v-btn  @click="CONFIRM_DELETE_ITEM_ORDER({
                                    order:SELECTED_ORDER,
                                    deleting_reason:'user_delete',
                                    notify:true,
                                })"  
                                color="primary" value="delete">
                                    <span class="hidden-sm-and-down">Delete</span> 
                                    <v-icon right>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
    
                                <v-btn @click="EDIT_ITEM_ORDER(SELECTED_ORDER)"  color="primary" value="edit">
                                    <span class="hidden-sm-and-down">Update</span> 
                                    <v-icon right>
                                        edit
                                    </v-icon>
                                </v-btn>
                                
                                <!-- @click="PRINT_ORDER_OPTION_DOCKET(SELECTED_ORDER,'bill_sale_order')" -->
                                
                                <v-menu v-if="MBS.actions.SIZE(ItemOrderOptionsData)>0"
                                    v-model="menu_select_order_docket" 
                                    :close-on-content-click="false" 
                                    offset-y  offset-overflow
                                    :nudge-width="90" rounded="xl">

                                    <template v-slot:activator="{ on }">   
                                        <v-btn v-on="on"  color="primary" value="print_docket">
                                            <span class="hidden-sm-and-down">Print Docket</span> 
                                            <v-icon right>
                                                print
                                            </v-icon>
                                        </v-btn>
                                    </template> 

                                    <v-card>
                                        <v-card-title primary-title class="secondary white--text">
                                            Select Docket Version
                                        </v-card-title>
                                        <v-list>
                                            <v-list-item  @click="PRINT_ORDER_OPTION_DOCKET(SELECTED_ORDER,'docket_all_updates')">
                                                For All Updates
                                            </v-list-item> 
                                            <v-list-item  @click="PRINT_ORDER_OPTION_DOCKET(SELECTED_ORDER,'docket_first_update')">
                                                First Update
                                            </v-list-item>
                                            <v-list-item  @click="PRINT_ORDER_OPTION_DOCKET(SELECTED_ORDER,'docket_last_update')">
                                                Last Update
                                            </v-list-item> 
                                        </v-list>
                                    </v-card>
                                </v-menu> 
    
                                <v-btn @click="PRINT_ORDER_OPTION(SELECTED_ORDER,'bill_sale_order')"  color="primary" value="print_order">
                                    <span class="hidden-sm-and-down">Print Bill</span> 
                                    <v-icon right>
                                        print
                                    </v-icon>
                                </v-btn>
    
                                <v-btn  @click="PAY_ITEM_ORDER(SELECTED_ORDER)"  color="primary" value="pay">
                                    <span class="hidden-sm-and-down">Pay</span> 
                                    <v-icon right>
                                        mdi-cash
                                    </v-icon>
                                </v-btn> 
                            </v-btn-toggle>
                        </v-layout> 
                    </v-card-actions>
                </v-card> 
            </v-dialog>  

            <!-- View ORDERS list Option DIALOG -->
            <span v-if="dialog_view_orders_list"> 
                <v-dialog
                    v-model="dialog_view_orders_list"
                    scrollable   
                    :persistent="false" :overlay="false"
                    max-width="900px"
                    transition="dialog-transition" >
                    <v-card rounded="lg" v-if="dialog_view_orders_list" > 
                        <v-card-title class="primary">
                            <div class="font-weight-bold b2--text"> 
                                <span >ORDER(S)</span> 
                            </div> 
                            <v-spacer></v-spacer> 
                            <v-btn class="ml-9" @click="dialog_view_orders_list=false" icon dark><v-icon >close</v-icon></v-btn>
                        </v-card-title>
                        <mbs-page-tab
                            v-model="currentOrderOptionTab"
                            color="b2"
                            text_color="black" dark
                            :items="TabItems"/> 
                        <v-card-text>
                            <br> 
                            <v-card outlined class="mbs-scroll-y"  :height="vsh-355">
                                <v-list class=""
                                    :bench="2" 
                                    item-height="70" >
                                    <!-- <template v-slot="{ item,index }"> -->
                                    <template v-for="(item,index) in PageItemOrders"> 
                                        <v-list-item @click="ON_VIEW_ORDER(item,index)" :key="index"> 
                                            <v-list-item-action>  
                                                <v-layout row wrap> 
                                                    <v-avatar :class="item.expand?'':''"
                                                        size="40"   
                                                        rounded="lg" class="ml-2"
                                                        color="grey"  >
                                                        <v-img v-if="item.image"></v-img>
                                                        <v-icon v-else>shopping_cart</v-icon>
                                                    </v-avatar> 
                                                </v-layout>
                                            </v-list-item-action>  
                                            <v-list-item-content>
                                                <v-list-item-title>{{item.waiter_name}}</v-list-item-title>
                                                <v-list-item-subtitle>Table:{{item.table_name}}</v-list-item-subtitle>
                                                <v-list-item-subtitle>Customer:{{item.customer_name}}</v-list-item-subtitle>
                                                <v-list-item-subtitle>Items: {{item.total_quantity_}} <span class="ml-3">Date:</span>{{item.local_created_at_}}</v-list-item-subtitle>
                                                
                                            </v-list-item-content>
                                                
                                            <v-list-item-action><v-card color="transparent" flat width="100">
                                                <v-layout class="ma-0">
                                                    <v-spacer></v-spacer>
                                                    {{item.total_sold_price_}}
                                                </v-layout>
                                            </v-card></v-list-item-action>  
                                            <v-list-item-action >
                                                <!-- <v-btn @click="CONFIRM_REMOVE_ITEM(item,index)" 
                                                :disabled="inputLoading" small icon><v-icon>delete</v-icon></v-btn> -->
                                                <v-btn  icon> 
                                                    <v-icon >chevron_right</v-icon>
                                                </v-btn> 
                                            </v-list-item-action> 
                                        </v-list-item> 
                                        <v-divider  :key="'_'+index"/> 
                                    </template>
                                </v-list> 
                            </v-card> 
                            <!-- <v-layout row wrap class="ma-0 mt-3"> 
                                <v-btn   @click="dialog_view_orders_list=false"
                                    :loading="inputLoading" class="mr-2"
                                    color="primary" height="50"  >
                                    <div class="not-f5 mx-5">EXIT</div>
                                    <v-icon>mdi-exit-to-app</v-icon>
                                </v-btn> 
                                <v-flex  >
                                    <v-btn  @click="CONFIRM_DELETE_ALL_ITEM_ORDER()" 
                                        type="submit"   
                                        :loading="inputLoading"
                                        color="secondary" height="50" width="100%" >
                                        <div class="not-f5">
                                            <v-icon class="pr-1 pb-1">mdi-delete</v-icon>
                                            Delete All
                                        </div>
                                    </v-btn>      
                                </v-flex>    
                            </v-layout> -->
                        </v-card-text>  
                    </v-card> 
                </v-dialog>  
            </span>
            
            <!-- View Customer ORDERS DIALOG -->
            <v-dialog
                v-model="dialog_view_customers_orders"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="900px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="dialog_view_customers_orders" >  
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text"> 
                            <span >TABLE ORDER(S)</span> 
                        </div> 
                        <v-spacer></v-spacer> 
                        <v-btn class="ml-9" @click="dialog_view_customers_orders=false" icon dark><v-icon >close</v-icon></v-btn>
                    </v-card-title>
                    <!-- <mbs-page-tab
                        v-model="currentOrderOptionTab"
                        color="b2"
                        text_color="black" dark
                        :items="TabItems"/>  -->
                    <v-card-text>
                        <br>  
                        <v-card-title>
                            {{MBS.actions.TEXT_UP(DATA.ITEMS.CUSTOMERS.names)}}  
                            <v-spacer></v-spacer> 
                            <v-text-field
                                v-model="search"
                                append-icon="search"
                                label="Search"
                                single-line 
                                clearable
                                hide-details
                            ></v-text-field>
                        </v-card-title>
                        <v-card outlined class="mbs-scroll-y"  :height="vsh-355"> 
                            <v-list v-if="MBS.actions.SIZE(TableItemOrders)!=0" class=""
                                :bench="2"
                                :items="TableItemOrders" 
                                item-height="70" >
                                <!-- <template v-slot="{ item,index }"> -->
                                <template v-for="(item,index) in TableItemOrders"> 
                                    <v-list-item @click="ON_VIEW_TABLE_ORDER(item,index)" > 
                                        <v-list-item-action>  
                                            <v-layout row wrap> 
                                                <v-avatar :class="item.expand?'':''"
                                                    size="40"   
                                                    rounded="lg" class="ml-2"
                                                    color="grey"  >
                                                    <v-img v-if="item.image"></v-img>
                                                    <v-icon v-else>mdi-account</v-icon>
                                                </v-avatar> 
                                            </v-layout>
                                        </v-list-item-action>  
                                        <v-list-item-content>
                                            <v-list-item-title>{{item.name}} </v-list-item-title>
                                            <v-list-item-subtitle>Order(s): {{item.orders_size}}</v-list-item-subtitle>
                                            
                                        </v-list-item-content>
                                            
                                        <v-list-item-action><v-card color="transparent" flat width="100">
                                            <v-layout class="ma-0" align-baseline justify-end>
                                                <v-spacer></v-spacer>  
                                                <span class="not-f2 pr-1">MWK</span>
                                                <div class=" not-f5 font-weight-bold grey--text text--darken-2">{{MBS.actions.money(item.total_sold_price)}}</div>
                                                
                                            </v-layout>
                                        </v-card></v-list-item-action>  
                                        <v-list-item-action > 
                                            <v-btn  icon> 
                                                <v-icon >chevron_right</v-icon>
                                            </v-btn> 
                                        </v-list-item-action> 
                                    </v-list-item> 
                                    <v-divider  /> 
                                </template>
                            </v-list> 
                            <v-layout v-else fill-height justify-center align-center>
                                <v-chip close v-if="search" large class="px-5" @click:close="search=''">
                                    <v-icon>warning</v-icon> 
                                    <div class="not-f5 ml-2 grey--text" >No Order match "{{ search }}"</div>
                                </v-chip>
                                <v-chip v-else large class="px-5">
                                    <v-icon>warning</v-icon> 
                                    <div class="not-f6 ml-2 grey--text" >No Orders</div>
                                </v-chip>
                            </v-layout>
                        </v-card> 
                        <v-layout row wrap class="ma-0 mt-3"> 
                            <v-btn   @click="dialog_view_customers_orders=false"
                                :loading="inputLoading" class="mr-2"
                                color="primary" height="50"  >
                                <div class="not-f5 mx-5">EXIT</div>
                                <v-icon>mdi-exit-to-app</v-icon>
                            </v-btn> 
                            <v-flex  >
                                <v-btn  @click="CONFIRM_DELETE_ALL_ITEM_ORDER({ 
                                    deleting_reason:'user_delete',
                                    notify:true
                                })" 
                                    type="submit"   
                                    :loading="inputLoading"
                                    color="secondary" height="50" width="100%" >
                                    <div class="not-f5">
                                        <v-icon class="pr-1 pb-1">mdi-delete</v-icon>
                                        Delete All
                                    </div>
                                </v-btn>      
                            </v-flex>    
                        </v-layout>
                    </v-card-text>  
                </v-card> 
            </v-dialog> 
            
            <!-- View TABLE ORDERS DIALOG -->
            <v-dialog
                v-model="dialog_view_table_orders"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="900px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="dialog_view_table_orders" > 
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text"> 
                            <span >TABLES ORDER(S)</span> 
                        </div> 
                        <v-spacer></v-spacer> 
                        <v-btn class="ml-9" @click="dialog_view_table_orders=false" icon dark><v-icon >close</v-icon></v-btn>
                    </v-card-title>
                    <!-- <mbs-page-tab
                        v-model="currentOrderOptionTab"
                        color="b2"
                        text_color="black" dark
                        :items="TabItems"/>  -->
                    <v-card-text>
                        <br>  
                        <v-card-title>
                            TABLES
                            <v-spacer></v-spacer> 
                            <v-text-field
                                v-model="search"
                                append-icon="search"
                                label="Search"
                                single-line 
                                clearable
                                hide-details
                            ></v-text-field>
                        </v-card-title>
                        <v-card outlined class="mbs-scroll-y"  :height="vsh-355"> 
                            <v-list v-if="MBS.actions.SIZE(TableItemOrders)!=0" class=""
                                :bench="2"
                                :items="TableItemOrders" 
                                item-height="70" >
                                <!-- <template v-slot="{ item,index }"> -->
                                <template v-for="(item,index) in TableItemOrders"> 
                                    <v-list-item @click="ON_VIEW_TABLE_ORDER(item,index)" :key="index"> 
                                        <v-list-item-action>  
                                            <v-layout row wrap> 
                                                <v-avatar :class="item.expand?'':''"
                                                    size="40"   
                                                    rounded="lg" class="ml-2"
                                                    color="grey"  >
                                                    <v-img v-if="item.image"></v-img>
                                                    <v-icon v-else>mdi-table-chair</v-icon>
                                                </v-avatar> 
                                            </v-layout>
                                        </v-list-item-action>  
                                        <v-list-item-content>
                                            <v-list-item-title>{{item.name}} </v-list-item-title>
                                            <v-list-item-subtitle>Order(s): {{item.orders_size}}</v-list-item-subtitle>
                                            
                                        </v-list-item-content>
                                            
                                        <v-list-item-action><v-card color="transparent" flat width="100">
                                            <v-layout class="ma-0" align-baseline justify-end>
                                                <v-spacer></v-spacer>  
                                                <span class="not-f2 pr-1">MWK</span>
                                                <div class=" not-f5 font-weight-bold grey--text text--darken-2">{{MBS.actions.money(item.total_sold_price)}}</div>
                                                
                                            </v-layout>
                                        </v-card></v-list-item-action>  
                                        <v-list-item-action > 
                                            <v-btn  icon> 
                                                <v-icon >chevron_right</v-icon>
                                            </v-btn> 
                                        </v-list-item-action> 
                                    </v-list-item> 
                                    <v-divider  :key="' '+index"/> 
                                </template>
                            </v-list> 
                            <v-layout v-else fill-height justify-center align-center>
                                <v-chip close v-if="search" large class="px-5" @click:close="search=''">
                                    <v-icon>warning</v-icon> 
                                    <div class="not-f5 ml-2 grey--text" >No Order match "{{ search }}"</div>
                                </v-chip>
                                <v-chip v-else large class="px-5">
                                    <v-icon>warning</v-icon> 
                                    <div class="not-f6 ml-2 grey--text" >No Orders</div>
                                </v-chip>
                            </v-layout>
                        </v-card> 
                        <v-layout row wrap class="ma-0 mt-3"> 
                            <v-btn   @click="dialog_view_table_orders=false"
                                :loading="inputLoading" class="mr-2"
                                color="primary" height="50"  >
                                <div class="not-f5 mx-5">EXIT</div>
                                <v-icon>mdi-exit-to-app</v-icon>
                            </v-btn> 
                            <v-flex  >
                                <v-btn  @click="CONFIRM_DELETE_ALL_ITEM_ORDER({ 
                                    deleting_reason:'user_delete',
                                    notify:true
                                })" 
                                    type="submit"   
                                    :loading="inputLoading"
                                    color="secondary" height="50" width="100%" >
                                    <div class="not-f5">
                                        <v-icon class="pr-1 pb-1">mdi-delete</v-icon>
                                        Delete All
                                    </div>
                                </v-btn>      
                            </v-flex>    
                        </v-layout>
                    </v-card-text>  
                </v-card> 
            </v-dialog> 

            <!-- View Selected TABLE ORDER  DIALOG -->
            <v-dialog
                v-model="dialog_view_selected_customer_order"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="700px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="LiveSelectedTableOrder" > 

                    <!-- HEADERS -->
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text"> 
                            <span>SELECTED TABLE ORDER(S)</span> 
                        </div> 
                        <v-chip small color="" class="ml-3">{{LiveSelectedTableOrder.orders_size}}</v-chip>
                        <v-spacer></v-spacer> 
                        <v-btn class="ml-9" @click="dialog_view_selected_customer_order=false" icon dark><v-icon >close</v-icon></v-btn>
                    </v-card-title>    
                    
                    <v-card-text class="b1">
                        <v-card outlined class="mt-3">
                            <v-card-text> 
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-table-chair</v-icon>
                                    <div>Table:</div>
                                    <v-spacer/>
                                    <div class="ml-2 font-weight-bold">{{ LiveSelectedTableOrder.name }}</div>
                                </v-layout>
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-receipt</v-icon>
                                    <div>Total Amount:</div>
                                    <v-spacer/>
                                    <span class="mt-2">MWK</span><div class="ml-1 font-weight-bold not-f7">{{ MBS.actions.money(LiveSelectedTableOrder.total_sold_price) }}</div>
                                </v-layout>
                            </v-card-text> 
                        </v-card>

                        <!-- LIST -->
                        <div class="pa-2">
                            <div class="font-weight-bold">Order list</div>
                            <v-card outlined class="mbs-scroll-y"  :height="vsh-405">
                                <v-list class=""
                                    :bench="2"
                                    :items="PageItemOrders" 
                                    item-height="70" > 
                                    <template v-for="(item,index) in LiveSelectedTableOrder.orders">
                                        
                                        <v-list-item @click="ON_VIEW_ORDER(item,index)"> 
                                            <v-list-item-action>  
                                                <v-layout row wrap> 
                                                    <v-avatar :class="item.expand?'':''"
                                                        size="40"   
                                                        rounded="lg" class="ml-2"
                                                        color="grey"  >
                                                        <v-img v-if="item.image"></v-img>
                                                        <v-icon v-else>shopping_cart</v-icon>
                                                    </v-avatar> 
                                                </v-layout>
                                            </v-list-item-action>  
                                            <v-list-item-content>
                                                <v-list-item-title>{{item.waiter?item.waiter.name:'...'}}</v-list-item-title>
                                                <v-list-item-subtitle>Customer: {{item.customer?item.customer.name:'...'}}</v-list-item-subtitle> 
                                                <v-list-item-subtitle>Items: {{item.total_quantity_}}</v-list-item-subtitle> 
                                                <v-list-item-subtitle>{{item.local_created_at_}}</v-list-item-subtitle> 
                                            </v-list-item-content>
                                                
                                            <v-list-item-action><v-card color="transparent" flat width="100">
                                                <v-layout class="ma-0">
                                                    <v-spacer></v-spacer>
                                                    <div>
                                                        {{item.total_sold_price_}} 
                                                    </div>
                                                </v-layout>
                                            </v-card></v-list-item-action>  
                                            <v-list-item-action > 
                                                <v-btn  icon> 
                                                    <v-icon >chevron_right</v-icon>
                                                </v-btn> 
                                            </v-list-item-action> 
                                        </v-list-item> 
                                        <v-divider  /> 
                                    </template>
                                </v-list> 
                            </v-card> 
                        </div>
                    </v-card-text>  
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-layout row wrap justify-center align-center class="ma-0">
                            <v-btn-toggle  dark  class="" >
                                <v-btn  @click="EXIT_DIALOG()"  color="primary" value="exit">
                                    <span class="hidden-sm-and-down mx-3">EXIT</span>  
                                    <v-icon>mdi-exit-to-app</v-icon>
                                </v-btn>
                                
                                <v-btn  @click="CONFIRM_DELETE_MULTIPLE_ITEM_ORDERS({
                                        item_order:LiveSelectedTableOrder,
                                        type:'waiter',
                                        deleting_reason:'user_delete',
                                        notify:true
                                    })" color="primary" value="left">
                                    <span class="hidden-sm-and-down">Delete All</span> 
                                    <v-icon right>
                                        mdi-delete
                                    </v-icon>
                                </v-btn> 
                                
                                <v-btn @click="PRINT_ORDER_OPTION(LiveSelectedTableOrder,'table_orders')"  color="primary" value="center">
                                    <span class="hidden-sm-and-down">Print Summary</span> 
                                    <v-icon right>
                                        print
                                    </v-icon>
                                </v-btn>
    
                                <v-btn  @click="PAY_ORDER_OPTION(LiveSelectedTableOrder)"  color="primary" value="right">
                                    <span class="hidden-sm-and-down">Pay All</span> 
                                    <v-icon right>
                                        mdi-cash
                                    </v-icon>
                                </v-btn> 
                            </v-btn-toggle> 
                        </v-layout>

                    </v-card-actions>
                </v-card> 
            </v-dialog> 
            
            <!-- View WAITERS ORDERS DIALOG -->
            <v-dialog
                v-model="dialog_view_waiter_orders"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="900px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="dialog_view_waiter_orders" > 
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text"> 
                            <span >WAITERS ORDER(S)</span> 
                        </div> 
                        <v-spacer></v-spacer> 
                        <v-btn class="ml-9" @click="dialog_view_waiter_orders=false" icon dark><v-icon >close</v-icon></v-btn>
                    </v-card-title>
                    <!-- <mbs-page-tab
                        v-model="currentOrderOptionTab"
                        color="b2"
                        text_color="black" dark
                        :items="TabItems"/>  -->
                    <v-card-text>
                        <br>  
                        <v-card-title>
                            WAITERS
                            <v-spacer></v-spacer> 
                            <v-text-field
                                v-model="search"
                                append-icon="search"
                                label="Search"
                                single-line 
                                clearable
                                hide-details
                            ></v-text-field>
                        </v-card-title>
                        <v-card outlined class="mbs-scroll-y"  :height="vsh-355"> 
                            <v-list v-if="MBS.actions.SIZE(WaiterItemOrders)!=0" class=""
                                :bench="2"
                                :items="WaiterItemOrders" 
                                item-height="70" >
                                <!-- <template v-slot="{ item,index }"> -->
                                <template v-for="(item,index) in WaiterItemOrders"> 
                                    <v-list-item @click="ON_VIEW_WAITER_ORDER(item,index)" :key="index"> 
                                        <v-list-item-action>  
                                            <v-layout row wrap> 
                                                <v-avatar :class="item.expand?'':''"
                                                    size="40"   
                                                    rounded="lg" class="ml-2"
                                                    color="grey"  >
                                                    <v-img v-if="item.image"></v-img>
                                                    <v-icon v-else>mdi-face-agent</v-icon>
                                                </v-avatar> 
                                            </v-layout>
                                        </v-list-item-action>  
                                        <v-list-item-content>
                                            <v-list-item-title>{{item.name}} </v-list-item-title>
                                            <v-list-item-subtitle>Order(s): {{item.orders_size}}</v-list-item-subtitle>
                                            
                                        </v-list-item-content>
                                            
                                        <v-list-item-action><v-card color="transparent" flat width="100">
                                            <v-layout class="ma-0" align-baseline justify-end>
                                                <v-spacer></v-spacer>  
                                                <span class="not-f2 pr-1">MWK</span>
                                                <div class=" not-f5 font-weight-bold grey--text text--darken-2">{{MBS.actions.money(item?.total_sold_price)}}</div>
                                                
                                            </v-layout>
                                        </v-card></v-list-item-action>  
                                        <v-list-item-action > 
                                            <v-btn  icon> 
                                                <v-icon >chevron_right</v-icon>
                                            </v-btn> 
                                        </v-list-item-action> 
                                    </v-list-item> 
                                    <v-divider  :key="' '+index"/> 
                                </template>
                            </v-list> 
                            <v-layout v-else fill-height justify-center align-center>
                                <v-chip close v-if="search" large class="px-5" @click:close="search=''">
                                    <v-icon>warning</v-icon> 
                                    <div class="not-f5 ml-2 grey--text" >No Order match "{{ search }}"</div>
                                </v-chip>
                                <v-chip v-else large class="px-5">
                                    <v-icon>warning</v-icon> 
                                    <div class="not-f6 ml-2 grey--text" >No Orders</div>
                                </v-chip>
                            </v-layout>
                        </v-card> 
                        <v-layout row wrap class="ma-0 mt-3"> 
                            <v-btn   @click="dialog_view_waiter_orders=false"
                                :loading="inputLoading" class="mr-2"
                                color="primary" height="50"  >
                                <div class="not-f5 mx-5">EXIT</div>
                                <v-icon>mdi-exit-to-app</v-icon>
                            </v-btn> 
                            <v-flex  >
                                <v-btn  @click="CONFIRM_DELETE_ALL_ITEM_ORDER({
                                    item_order:LiveSelectedWaiterOrder,
                                    type:'waiter',
                                    deleting_reason:'user_delete',
                                    notify:true
                                })" 
                                    type="submit"   
                                    :loading="inputLoading"
                                    color="secondary" height="50" width="100%" >
                                    <div class="not-f5">
                                        <v-icon class="pr-1 pb-1">mdi-delete</v-icon>
                                        Delete All
                                    </div>
                                </v-btn>      
                            </v-flex>    
                        </v-layout>
                    </v-card-text>  
                </v-card> 
            </v-dialog> 

            <!-- View Selected WAITER ORDER  DIALOG -->
            <v-dialog
                v-model="dialog_view_selected_waiter_order"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="700px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="LiveSelectedWaiterOrder" > 
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text"> 
                            <span>SELECTED WAITER ORDER</span> 
                        </div> 
                        <v-chip small color="" class="ml-3">{{LiveSelectedWaiterOrder.orders_size}}</v-chip>
                        <v-spacer></v-spacer> 
                        <v-btn class="ml-9" @click="dialog_view_selected_waiter_order=false" icon dark><v-icon >close</v-icon></v-btn>
                    </v-card-title>    
                    
                    <v-card-text class="b1">
                        <v-card outlined class="mt-3">
                            <v-card-text> 
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-face-agent</v-icon>
                                    <div>Waiter:</div>
                                    <v-spacer/>
                                    <div class="ml-2 font-weight-bold">{{ LiveSelectedWaiterOrder.name }}</div>
                                </v-layout>
                                <v-layout align-center class="my-1">
                                    <v-icon class="mr-2">mdi-receipt</v-icon>
                                    <div>Total Amount:</div>
                                    <v-spacer/>
                                    <span class="mt-2">MWK</span><div class="ml-1 font-weight-bold not-f7">{{ MBS.actions.money(LiveSelectedWaiterOrder.total_sold_price) }}</div>
                                </v-layout>
                            </v-card-text> 
                        </v-card>
                        <div class="pa-2">
                            <div class="font-weight-bold">Order list</div>
                            <v-card outlined class="mbs-scroll-y"  :height="vsh-415">
                                <v-list class=""
                                    :bench="2"
                                    :items="PageItemOrders" 
                                    item-height="70" > 
                                    <template v-for="(item,index) in LiveSelectedWaiterOrder.orders">
                                        
                                        <v-list-item @click="ON_VIEW_ORDER(item,index)"> 
                                            <v-list-item-action>  
                                                <v-layout row wrap> 
                                                    <v-avatar :class="item.expand?'':''"
                                                        size="40"   
                                                        rounded="lg" class="ml-2"
                                                        color="grey"  >
                                                        <v-img v-if="item.image"></v-img>
                                                        <v-icon v-else>shopping_cart</v-icon>
                                                    </v-avatar> 
                                                </v-layout>
                                            </v-list-item-action>  
                                            <v-list-item-content>
                                                <!-- <v-list-item-title>{{item.waiter?item.waiter.name:'...'}}</v-list-item-title> -->
                                                <v-list-item-title>{{item.table?item.table.name:'...'}}</v-list-item-title>
                                                <v-list-item-subtitle>Items: {{item.total_quantity_}}</v-list-item-subtitle> 
                                                <v-list-item-subtitle>{{item.local_created_at_}}</v-list-item-subtitle> 
                                            </v-list-item-content>
                                                
                                            <v-list-item-action><v-card color="transparent" flat width="100">
                                                <v-layout class="ma-0">
                                                    <v-spacer></v-spacer>
                                                    <div>
                                                        {{item.total_sold_price_}} 
                                                    </div>
                                                </v-layout>
                                            </v-card></v-list-item-action>  
                                            <v-list-item-action > 
                                                <v-btn  icon> 
                                                    <v-icon >chevron_right</v-icon>
                                                </v-btn> 
                                            </v-list-item-action> 
                                        </v-list-item> 
                                        <v-divider  /> 
                                    </template>
                                </v-list> 
                            </v-card> 
                        </div>
                    </v-card-text>  
                    <v-card-actions>
                        <v-spacer/> 

                        <v-btn-toggle  dark  class="mb-2 mr-2" >
                            <v-btn  @click="EXIT_DIALOG()"  color="primary" value="exit">
                                <span class="hidden-sm-and-down mx-3">EXIT</span>  
                                <v-icon>mdi-exit-to-app</v-icon>
                            </v-btn>
                            
                            <v-btn  @click="CONFIRM_DELETE_MULTIPLE_ITEM_ORDERS({
                                item_order:LiveSelectedWaiterOrder,
                                type:'waiter',
                                deleting_reason:'user_delete',
                                notify:true
                            })"  color="primary" value="left">
                                <span class="hidden-sm-and-down">Delete All</span> 
                                <v-icon right>
                                    mdi-delete
                                </v-icon>
                            </v-btn> 
                            
                            <v-btn @click="PRINT_ORDER_OPTION(LiveSelectedTableOrder,'table_orders')"  color="primary" value="center">
                                <span class="hidden-sm-and-down">Print Summary</span> 
                                <v-icon right>
                                    print
                                </v-icon>
                            </v-btn>

                            <v-btn  @click="PAY_ORDER_OPTION(LiveSelectedTableOrder)"  color="primary" value="right">
                                <span class="hidden-sm-and-down">Pay All</span> 
                                <v-icon right>
                                    mdi-cash
                                </v-icon>
                            </v-btn> 
                        </v-btn-toggle>
                    </v-card-actions>
                </v-card> 
            </v-dialog> 

            <!-- //print -->
            <div v-if="auto_print.printing"> 
                <mbs-auto-print 
                    @finish_print="FINISH_PRINT"
                    :show="true"  
                    :auto_print="auto_print.auto"
                    :auto_printer_name="auto_print.auto_printer_name"
                    :com_printer="auto_print.com_printer"  
                    :data="auto_print.print_data"
                    :type="auto_print.print_type"
                    :title="auto_print.print_title"
                    :printing_action="auto_print.printing_action"
                    :display_option="auto_print.display_option" 
                    />  
            </div> 
        </div>
    </v-layout>
</template> 
<script>
let PAGE_NAME = 'ORDER-OPTION'
import DATA from "../../plugins/DATA"
import DB from "../../plugins/DB"
import {mapState,mapGetters } from "vuex" 
import { toNumber } from '../../plugins/formatNumber'
export default { 
    props:['show','disabled','hight','width','dense','waiter_uid','item_order'],
    data(){
        return{  
            body_height:0,
            dialog:false, 
            dialog_new_order_option:false, 
            dialog_update_order_option:false, 
            dialog_view_orders_list:false, 
            dialog_view_customers_orders:false, 
            dialog_view_options_orders:false, 
            dialog_view_table_orders:false, 
            dialog_view_selected_customer_order:false, 
            dialog_view_waiter_orders:false, 
            dialog_view_selected_waiter_order:false, 
            dialog_view_selected_option_order:false, 
            dialog_view_selected_option_order:false, 
            dialog_view_order:false, 
            menu_select_order_docket:false,
            menu_select_order_option:false,
            menu_update_order_option:false,
            menu_update_existing_order_option:false,
            currentOrderOptionTab:null,  
            input:{}, 
            inputLoading:false, 
            search:null, 
            menuSelect:false,
            PUT_AND_PRINT:false,
            ORDER_PRINT_OPTIONS:null,
            SELECTED_ORDER_OPTION:null,
            SELECTED_ORDER:null, 
            SELECTED_CUSTOMER_ORDER:null, 
            SELECTED_WAITER_ORDER:null, 
            DATA:DATA,  
            clearing_item_orders:false,

            auto_print:{
                auto:false,
                printing:false, 
                print_data:null,
                print_type:null,
                com_printer:false, 
                print_title:null, 
                auto_printer_name:null,
                printing_action:null, 
                display_option:'',  
            },
            
        } 
    }, 
    created() {
        try {
            this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);   
            this.MBS.events.$on('SUBMIT_ITEM_ORDERS', this.SUBMIT_ITEM_ORDERS);   
            this.MBS.events.$on('ON_VIEW_ORDERS', this.ON_VIEW_ORDERS);   

            this.MBS.events.$on('CONFIRM_DELETE_ITEM_ORDER', this.CONFIRM_DELETE_ITEM_ORDER);   
            this.MBS.events.$on('CONFIRM_DELETE_ALL_ITEM_ORDER', this.CONFIRM_DELETE_ALL_ITEM_ORDER);   
            this.MBS.events.$on('DELETE_ITEM_ORDER', this.DELETE_ITEM_ORDER);   
            this.MBS.events.$on('DELETE_MULTIPLE_ITEM_ORDERS', this.DELETE_MULTIPLE_ITEM_ORDERS);   
            this.MBS.events.$on('DELETE_ALL_ITEM_ORDER', this.DELETE_ALL_ITEM_ORDER);   
        } catch (error) {
            this.MBS.actions.error({
                error:error,
                from:'created',
                page:PAGE_NAME, 
            }) 
        }
    },
    mounted(){  
    },
    computed:{
        route(){
            return this.$route
        },
        vs(){

            return this.$vuetify.breakpoint
        }, 
        vsh(){
            return this.vs.height
        }, 


        vsb(){ 
            let body_height = this.body_height 
            let height = this.vs.height 
            body_height = this.$refs.body_height?this.$refs.body_height.$el.clientHeight:body_height;
            let diff = height-body_height
            return {
                height:height,
                body_height:body_height,
                diff:diff,
                scroll:body_height?diff>180?false:true:false,
            }
        },
        ...mapState({
            loading:state=>state.load.loading,
            processes:state=>state.load.processes,
            responses:state=>state.load.responses,
        }),
        ...mapState({
            us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
            ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
            K_CONNECTOR: state=> state.items['K_CONNECTOR'],
        }),
        ...mapState({
            CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
            CompanyQuickActions: state=> state.items[(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values).toUpperCase()], 
            Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
            JoinedOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
            CompanyUsers: state=> state.users[(DATA.ITEMS.COMPANY_USERS.values).toUpperCase()],
            JoinedCompanyUsers: state=> state.join[(DATA.ITEMS.COMPANY_USERS.values).toUpperCase()],  
            Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
            JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
            ItemOrders: state=> state.items[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
            JoinedItemOrders: state=> state.join[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
            LocalItemOrders: state=> state.items[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
            JoinedLocalItemOrders: state=> state.join[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
            ItemOrderOptions: state=> state.items[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()],
            JoinedItemOrderOptions: state=> state.join[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()], 
            Payments: state=> state.items[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
            JoinedPayments: state=> state.join[(DATA.ITEMS.PAYMENTS.values).toUpperCase()], 
             
            Customers: state=> state.items[(DATA.ITEMS.CUSTOMERS.values).toUpperCase()], 
                JoinedCustomers: state=> state.join[(DATA.ITEMS.CUSTOMERS.values).toUpperCase()], 
                CustomerGroups: state=> state.items[(DATA.ITEMS.CUSTOMER_GROUPS.values).toUpperCase()], 
                JoinedCustomerGroups: state=> state.join[(DATA.ITEMS.CUSTOMER_GROUPS.values).toUpperCase()], 
            SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
            JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],  
            LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
            JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
            JoinedLocalCashups: state=> state.join[(DATA.ITEMS.LOCAL_CASHUPS.values).toUpperCase()],
            Tills: state=> state.items[(DATA.ITEMS.TILLS.values).toUpperCase()],
            JoinedTills: state=> state.join[(DATA.ITEMS.TILLS.values).toUpperCase()],  

            LocalCashups: state=> state.locals[(DATA.ITEMS.LOCAL_CASHUPS.values).toUpperCase()],
 
            OrderOptionTypes: state=> state.app['ODER_OPTION_TYPES'],
        }), 
        CustomersData(){
            const Customers = this.Customers
            const JoinedCustomers = this.JoinedCustomers  
            return JoinedCustomers?JoinedCustomers:Customers
        }, 
        CustomerGroupsData(){
            const CustomerGroups = this.CustomerGroups
            const JoinedCustomerGroups = this.JoinedCustomerGroups  
            return JoinedCustomerGroups?JoinedCustomerGroups:CustomerGroups
        },
        OutletsData(){
            return this.JoinedOutlets?this.JoinedOutlets:this.Outlets
        }, 
        TillsData(){
            return this.JoinedTills?this.JoinedTills:this.Tills
        }, 
        ItemOrderOptionsData(){   
            return this.JoinedItemOrderOptions?this.JoinedItemOrderOptions:this.ItemOrderOptions
        }, 
        ItemOrdersData(){   

            return this.JoinedItemOrders?this.JoinedItemOrders:this.ItemOrders
        },  
        LocalItemOrdersData(){  
            return this.JoinedLocalItemOrders?this.JoinedLocalItemOrders:this.LocalItemOrders
        },  
        AllItemOrders(){  
            const local = this.LocalItemOrdersData
            const live = this.ItemOrdersData   
            const find = key=>{
                if(!live){return null}
                return live.find(order=>order.key == key)
            } 
            let items = []
            if (this.MBS.actions.SIZE(live)>0) {
                items.push(...live)
            }
            if (this.MBS.actions.SIZE(local)>0) {
                local.forEach(element => {
                    // let uploaded_order = find(element.key)
                    const order_index =items.findIndex(order=>order.key == element.key)
                    if (order_index==-1) {
                        items.push(element)
                    }else{
                        items[order_index] = {
                            ...items[order_index],
                            ...element
                        }
                    }
                });
            }
            items = items.filter(order=>!order.deleted)
            if(!items){return null}
            const list = [...items]
            list.sort(this.MBS.actions.dynamicSort("key",false))     
            return list 
        }, 
        AllItemOrdersByWaiter(){
            const waiter = this.CurrentWaiter 
            const orders = this.AllItemOrders  
            let company_user_group_code = waiter?.company_user_group_code
            let company_user_group_name = waiter?.company_user_group_name
            let waiter_key = waiter?.key
            
            if (company_user_group_name != "Waiters") {
                return orders
            }
            if(!orders){return null}
            let filtered = orders.filter(order=>order.waiter_key == waiter_key) 
            return filtered

        },
        ActionAndProcessingOrderOption(){
            const options = this.ItemOrderOptionsData
            if(!options){return null}
            const items = options.filter(item=>item.type_key == "action_order"||item.type_key == "processing_order")
            return items
        },
        TableItemOrders(){  
            const orders = this.AllItemOrdersByWaiter
            const search = this.search
            if(!orders){return null}
            let tables = []
            orders.forEach(element => {
                const table = element.table?element.table:{name:"Unknown",key:"unknown"}
                const table_name = table?table.name:''
                const total_sold_price = this.toNumber(element.total_sold_price)
                const index = tables.findIndex(item=>item.key==table.key)
                let include = true
                if (search && table_name) {
                    let name_index = this.MBS.actions.TEXT_UP0(table_name).indexOf(this.MBS.actions.TEXT_UP0(search))
                    if (name_index == -1) {
                        include = false
                    } 
                }

                if (include) {
                    if (index==-1) {
                        let table_orders = {
                            ...table,
                            total_sold_price:total_sold_price,
                            orders_size:1,
                            orders:[]
                        }
                        table_orders.orders.push(element)
                        tables.push(table_orders)
                    } else {
                        tables[index].orders.push(element)
                        tables[index].orders_size++
                        tables[index].total_sold_price = tables[index].total_sold_price+total_sold_price  
                    } 
                }
            }); 
            return tables
            
        }, 
        WaiterItemOrders(){  
            const orders = this.AllItemOrdersByWaiter
            const search = this.search
            if(!orders){return null}
            let tables = []
            orders.forEach(element => {
                const waiter = element.waiter?element.waiter:{name:"Unknown",key:"unknown"}
                const waiter_name = waiter?waiter.name:''
                const total_sold_price = this.toNumber(element.total_sold_price)
                const index = tables.findIndex(item=>item.key==waiter.key)
                let include = true
                if (search && waiter_name) {
                    let name_index = this.MBS.actions.TEXT_UP0(waiter_name).indexOf(this.MBS.actions.TEXT_UP0(search))
                    if (name_index == -1) {
                        include = false
                    } 
                }

                if (include) {
                    if (index==-1) {
                        let table_orders = {
                            ...waiter,
                            total_sold_price:total_sold_price,
                            orders_size:1,
                            orders:[]
                        }
                        table_orders.orders.push(element)
                        tables.push(table_orders)
                    } else {
                        tables[index].orders.push(element)
                        tables[index].orders_size++
                        tables[index].total_sold_price = tables[index].total_sold_price+total_sold_price  
                    } 
                }
            }); 
            return tables
            
        }, 

        LiveSelectedTableOrder(){  
            const selected = this.SELECTED_CUSTOMER_ORDER
            const orders = this.TableItemOrders 
            if(!selected || !orders){
                this.dialog_view_selected_customer_order = false
                return null
            }
            const live_order = orders.find(order=>order.key == selected.key)
            if (!live_order) {
                this.dialog_view_selected_customer_order = false
            }
            return live_order
        },
        LiveSelectedWaiterOrder(){  
            const selected = this.SELECTED_WAITER_ORDER
            const orders = this.WaiterItemOrders 
            if(!selected || !orders){
                this.dialog_view_selected_waiter_order = false
                return null
            }
            const live_order = orders.find(order=>order.key == selected.key)
            if (!live_order) {
                this.dialog_view_selected_waiter_order = false
            }
            return live_order
        },


        Waiters(){
            const options = this.ItemOrderOptionsData
            if(!options){return null}
            const items = options.filter(item=>item.type_key == "waiter")
            return items
        },
        Tables(){
            const options = this.ItemOrderOptionsData
            if(!options){return null}
            const items = options.filter(item=>item.type_key == "table")
            return items
        },

        CurrentTill(){
            const tills = this.TillsData
            const cashup = this.LocalCashup 
            if(!tills || !cashup){return null}
            const filled = tills.find(item=>{
                return item.key == cashup.till_key
            })
            return filled
        },
        CurrentOutlet(){
            const outlets = this.OutletsData
            const till = this.CurrentTill   
            if(!outlets || !till){return null}
            const filled = outlets.find(outlet=>outlet.code==till.outlet_code) 
            return filled
        }, 
        CurrentWaiter(){
            const ud = this.ud
            const uid = ud?.uid
            // const waiter_uid = this.waiter_uid ? this.waiter_uid: uid
            const waiter_uid = this.waiter_uid  


            const waiters = this.Waiters     
            if(!waiters || !waiter_uid){return null}
            const filtered = waiters.find(waiter=>waiter.uid==waiter_uid) 
            let user  = this.MBS.actions.JOIN_USER(this.$store.getters.getCompanyUserByKey(waiter_uid))
            let company_user_group_code = user?.company_user_group_code
            let company_user_group = user?.company_user_group
            let company_user_group_name = company_user_group?.name
            let details = null
            if (user) {
                details = {
                    user:user,
                    company_user_group_code:company_user_group_code,
                    company_user_group:company_user_group,
                    company_user_group_name:company_user_group_name,
                }
                
            }
            if (filtered) {
                details = {
                    ...filtered,
                    ...details 
                }
            }
            return details
        }, 
          
        MyCompanies(){ 
            return this.$store.getters.getMyCompanies
        },
        JoinedMyCompanies(){ 
            const companies = this.MyCompanies 
            if(!companies){return null}
            let joined = this.MBS.actions.JOIN({
                name:DATA.ITEMS.COMPANIES.values,
                items:companies
            })  
            return joined
        }, 
        LocalCashup(){   
            let cashups = this.LocalCashups
            let local_cashup = cashups?cashups.find(item=>{
                return !item.closed
            }):null 
            return local_cashup
        },
        Joined_LocalCashup(){   
            let localCashup = this.LocalCashup
            let joined = this.MBS.actions.JOIN_CASHUP(localCashup)  
            return joined
        },
        DataSource(){
            const Orders = this.AllItemOrdersByWaiter  
            const Items = this.ItemsData  
            const ItemCategories = this.ItemCategories  
            const ItemDepartments = this.ItemDepartments  
                

            const Outlets = this.OutletsData  
            const Tills = this.DayShiftsTills 
            const DayShifts = this.UNCLOSED_DayShifts  
            const Customers = this.CustomersData  
            const CustomerGroups = this.CustomerGroups  
                
            const ReceivableAccounts = this.ReceivableAccounts  
            const SellingModels = this.sellingModels   
            const AllPrinters = this.AllPrinters   
            const PricingTiers = this.AllPricingTiers   
            const Tables = this.Tables   
            const Waiters = this.Waiters   
            return {
                Orders:Orders, 
                Items:Items, 
                ItemCategories:ItemCategories, 
                ItemDepartments:ItemDepartments, 
                    

                Outlets:Outlets, 
                Tills:Tills, 
                DayShifts:DayShifts, 
                Customers:Customers,
                CustomerGroups:CustomerGroups,
                    
                ReceivableAccounts:ReceivableAccounts,
                SellingModels:SellingModels,
                AllPrinters:AllPrinters,
                PricingTiers:PricingTiers,
                Tables:Tables,
                Waiters:Waiters,
            }
        }, 
        TabItems(){  
            const all = this.ActionAndProcessingOrderOption
            if(!all){return null}
            let tab =null

            let order_options = [
                {
                    name:"All",
                    key:'all',
                    code:'all',
                    // indicator:{
                    //     value:this.MBS.actions.SIZE(this.AllItemOrdersByWaiter),
                    //     color:'primary'
                    // }
                }
            ]
            order_options.push(...all)
            const findOrder = option=>{
                if(!this.AllItemOrdersByWaiter){return null}
                if(option.key=='all'){return this.AllItemOrdersByWaiter}
                return this.AllItemOrdersByWaiter.filter(item=>{
                    let departs = item.joined_items_departments
                    let depart = departs?departs.find(depart=>depart.key == option.item_department_key):null
                    return depart || item.order_option_key == option.key
                })
            }
            order_options.forEach(element => {
                if(!tab){tab=[]}
                tab.push({
                    ...element,
                    indicator:{
                        value:this.MBS.actions.SIZE(findOrder(element)),
                        color:'primary'
                    }
                })
            });
            return tab
        }, 
        PageItemOrders(){  
            const tab = this.currentOrderOptionTab
            const orders = this.AllItemOrdersByWaiter
            const order_options = [
                {
                    name:"All",
                    key:'all',
                    code:'all',
                    // indicator:{
                    //     value:this.MBS.actions.SIZE(this.AllItemOrdersByWaiter),
                    //     color:'primary'
                    // }
                }
            ]  
            if ( this.ActionAndProcessingOrderOption) {
                order_options.push(... this.ActionAndProcessingOrderOption) 
            } 
            let current_option = null

             
            if(order_options){
                current_option = order_options[tab]
            }   
            if(!orders){return null}
            if(!current_option){return orders}
            if(current_option?.key=="all"){return orders}
            const filtered = orders.filter(item=>{
                let departs = item.joined_items_departments
                let depart = departs?departs.find(depart=>depart.key == current_option.item_department_key):null
                return depart || item.order_option_key == current_option.key
            }) 
            return filtered
        }, 
 
         
        liveTime(){
            let time = this.MBS.date.live 
            return time
        },  
        online(){   
            return this.$store.getters.getOnlineStatus
        },
         
    },
    methods:{  
        async CLEAR_ITEM_ORDERS(LocalCashup){
            try {   
                let day_shift_key = this.LocalCashup?.day_shift_key
                let cashup_key = this.LocalCashup?.key
                if (!cashup_key) {return}
                if(this.clearing_item_orders){
                    setTimeout(() => {
                        this.CLEAR_ITEM_ORDERS(LocalCashup) 
                    }, 10000);
                }else{
                    this.clearing_item_orders = true
                    let res_local_sales = await DB.getItems({
                        name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values
                    })
                    let local_item_orders = res_local_sales?.data
                    let delete_orders = local_item_orders?local_item_orders.filter(order=>{
                        return order.uploaded && order.cashup_key!=cashup_key
                    }):null
                    if (this.MBS.actions.SIZE(delete_orders)) { 
                        let res =  await DB.deleteItems({ 
                            name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values, 
                            update_locals:true, 
                            items:delete_orders
                        }) 
                    } 
                    this.clearing_item_orders = false
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CLEAR_SELECTED_ITEMS',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        RESET_ALL_DATA(){ 
            try { 
                Object.assign(this.$data, this.$options.data())  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'RESET_ALL_DATA',
                    page:PAGE_NAME, 
                }) 
            }
        },
        SELECT_ORDER_OPTION(order_option){ 
            try { 
                let outlet = this.CurrentOutlet
                let waiter = this.CurrentWaiter
                let waiter_key =waiter?.key
                let order_option_key =order_option?.key
                if (waiter_key) {
                    this.input.waiter_key = waiter_key
                } 
                if (order_option_key == "update_order") {
                    this.dialog_update_order_option = true
                    this.SELECTED_ORDER_OPTION = order_option
                } else if (outlet.outlet_type_code == "restaurant") {
                    this.dialog_new_order_option= true
                    this.SELECTED_ORDER_OPTION= order_option
                } else {
                    this.$emit("on_select_order_option",order_option) 
                }
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'RESET_ALL_DATA',
                    page:PAGE_NAME, 
                }) 
            }
        },
        CREATE_NEW_ORDER(input,order_option){ 
            try { 
                let data = {
                    ...input,
                    print:this.PUT_AND_PRINT, 
                } 
                this.inputLoading = true  
                this.$emit("send_kitchen_order_option",data,order_option) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CREATE_NEW_ORDER',
                    page:PAGE_NAME, 
                }) 
            }
        },
        UPDATE_EXISTING_ORDER(input,order_option,order_printing_option){ 
            try { 
                let data = {
                    ...input,
                    print:this.PUT_AND_PRINT, 
                    order_printing_option:order_printing_option
                }  
                this.dialog_update_order_option= false
                this.$emit("CONFIRM_UPDATE_EXISTING_ORDER",data,order_option) 
                this.ORDER_PRINT_OPTIONS = null
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CREATE_NEW_ORDER',
                    page:PAGE_NAME, 
                }) 
            }
        },
        ON_UPDATE_ORDER_OPTION(payload){ 
            try {
                this.menu_update_order_option = false
                const item_order = this.item_order
                this.$emit("ON_UPDATE_ORDER_OPTION",{
                    ...payload,
                    item_order:item_order
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CREATE_NEW_ORDER',
                    page:PAGE_NAME, 
                }) 
            }
        },
        ON_UPDATE_EXISTING_ORDER_OPTION(option){ 
            try {
                this.menu_update_existing_order_option = false
                this.ORDER_PRINT_OPTIONS = option 
                this.$refs.ref_btn_update_print.$el.click();
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CREATE_NEW_ORDER',
                    page:PAGE_NAME, 
                }) 
            }
        },
        
        SUBMIT_ITEM_ORDERS(){ 
            try {  
                this.inputLoading = false
                this.dialog_new_order_option = false
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'RESET_ALL_DATA',
                    page:PAGE_NAME, 
                }) 
            }
        },
        ON_VIEW_ORDERS(type){
            try {     
                if (type == "view_orders_list") {
                    this.dialog_view_orders_list = !this.dialog_view_orders_list 
                } else if (type == "view_table_orders") {
                    this.dialog_view_table_orders = !this.dialog_view_table_orders 
                } else if (type == "view_waiter_orders") {
                    this.dialog_view_waiter_orders = !this.dialog_view_waiter_orders 
                } else {
                    
                }
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'ON_VIEW_ORDERS',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        ON_VIEW_ORDER(order,index){
            try {   
                this.SELECTED_ORDER = {...order,expand_items:true,expand_details:false}
                this.dialog_view_order = !this.dialog_view_order
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'ON_VIEW_ORDER',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        ON_VIEW_TABLE_ORDER(order,index){
            try {     
                this.SELECTED_CUSTOMER_ORDER = order
                this.dialog_view_selected_customer_order = !this.dialog_view_selected_customer_order
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'ON_VIEW_ORDER',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        ON_VIEW_WAITER_ORDER(order,index){
            try {     
                this.SELECTED_WAITER_ORDER = order
                this.dialog_view_selected_waiter_order = !this.dialog_view_selected_waiter_order
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'ON_VIEW_ORDER',
                    page:PAGE_NAME, 
                }) 
            } 
        },

        EXIT_DIALOG(){
            try { 
                this.dialog_print_order = false 

                this.dialog_new_order_option = false 
                this.dialog_update_order_option = false 
                this.dialog_view_orders_list = false 
                this.dialog_view_customers_orders = false 
                this.dialog_view_options_orders = false 
                this.dialog_view_table_orders = false 
                this.dialog_view_selected_customer_order = false 
                this.dialog_view_waiter_orders = false 
                this.dialog_view_selected_waiter_order = false 
                this.dialog_view_selected_option_order = false  
                this.dialog_view_order = false 

            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'EXIT_DIALOG',
                    page:PAGE_NAME, 
                }) 
            } 
        },

        
        EDIT_ITEM_ORDER(order){ 
            try {  
                this.EXIT_DIALOG()
                this.$emit("EDIT_ITEM_ORDER",order) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'EDIT_ITEM_ORDER',
                    page:PAGE_NAME, 
                }) 
            }
        },
        PRINT_ORDER_OPTION_DOCKET(order,version){ 
            try {  
                this.$emit("PRINT_ORDER_OPTION_DOCKET",{
                    ...order,
                    docket_version:version
                },'bill_sale_order') 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'PRINT_ORDER_OPTION_DOCKET',
                    page:PAGE_NAME, 
                }) 
            }
        },
        PRINT_ORDER_OPTION(order,order_type){ 
            try {   
                this.$emit("PRINT_ORDER_OPTION",order,order_type?order_type:'bill_sale_order') 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'PRINT_ORDER_OPTION',
                    page:PAGE_NAME, 
                }) 
            }
        },
        PAY_ITEM_ORDER(order){ 
            try {  
                this.$emit("PAY_ITEM_ORDER",order) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'PAY_ITEM_ORDER',
                    page:PAGE_NAME, 
                }) 
            }
        },

        //--------------[DELETE]------------
        CONFIRM_DELETE_ITEM_ORDER(payload){ 
            try {   
                let order = payload?.order
                let deleting_reason = payload?.deleting_reason
                let notify = payload?.notify

                let settings = this.CompanySettings
                let require_void_to_delete_order = settings?.require_void_to_delete_order
                let mbs_text = this.MBS.text.item_action("Order","Delete")
                let icon = "mdi-delete-empty"
                let title = ""+mbs_text.title
                let text = mbs_text.text
                let dialog =  this.MBS.actions.dialog
                if (require_void_to_delete_order) {
                    icon = "mdi-shield-lock-outline"
                    title = "VOID REQUIRED TO DELETE ORDER"
                    text = "Deleting order(s) require admin permission. Enter your admin phone and password to authorize this action."
                    dialog = this.MBS.actions.dialogConfirmPassword
                }
                dialog({
                    show:true,
                    fixed:true,
                    icon:icon,
                    title:title,
                    text:text,
                    btnYes:mbs_text.btnYes,  
                    btnNo:mbs_text.btnNo,  
                    action:{
                        code:PAGE_NAME+"=DELETE-ITEM-ORDER",
                        data:payload, 
                        all_users:require_void_to_delete_order,
                        get_access:{
                            page_name:"Sale",
                            company_user_group_name:"Admin"
                        }
                    }
                })  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CONFIRM_DELETE_ITEM_ORDER',
                    page:PAGE_NAME, 
                }) 
            }
        }, 
        CONFIRM_DELETE_MULTIPLE_ITEM_ORDERS(payload){ 
            try {   
                let deleting_reason = payload?.deleting_reason
                let notify = payload?.notify
                let item_order = payload?.item_order
                let name = item_order?.name
                let type_key = item_order?.type_key
                let orders = item_order?.orders
                let orders_size = this.MBS.actions.SIZE(orders)

                console.log(payload,'pay...............');

                let settings = this.CompanySettings
                let require_void_to_delete_order = settings?.require_void_to_delete_order
                let mbs_text = this.MBS.text.item_action(name+" Order(s)","Delete")
                let icon = "mdi-delete-empty"
                let title = ""+mbs_text.title
                let text = mbs_text.text+" "+orders_size+type_key+" order(s) will be deleted."
                let dialog =  this.MBS.actions.dialog
                if (require_void_to_delete_order) {
                    icon = "mdi-shield-lock-outline"
                    title = "VOID REQUIRED TO DELETE ORDER"
                    text = "Deleting order(s) require admin permission. Enter your admin phone and password to authorize this action."
                    dialog = this.MBS.actions.dialogConfirmPassword
                }
                dialog({
                    show:true,
                    fixed:true,
                    icon:icon,
                    title:title,
                    text:text,
                    btnYes:mbs_text.btnYes,  
                    btnNo:mbs_text.btnNo,  
                    action:{
                        code:PAGE_NAME+"=DELETE-MULTIPLE-ITEM-ORDERS",
                        data:payload, 
                        all_users:require_void_to_delete_order,
                        get_access:{
                            page_name:"Sale",
                            company_user_group_name:"Admin"
                        }
                    }
                })  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CONFIRM_DELETE_MULTIPLE_ITEM_ORDERS',
                    page:PAGE_NAME, 
                }) 
            }
        }, 
        CONFIRM_DELETE_ALL_ITEM_ORDER(payload){ 
            try {   
                const orders = this.AllItemOrders
                const orders_size = this.MBS.actions.SIZE(orders)
                let settings = this.CompanySettings
                let require_void_to_delete_order = settings?.require_void_to_delete_order
                let mbs_text = this.MBS.text.item_action("All Order(s)","Delete")
                let icon = "mdi-delete-empty"
                let title = ""+mbs_text.title
                let text = mbs_text.text+" "+orders_size+" order(s) will be deleted."
                let dialog =  this.MBS.actions.dialog
                if (require_void_to_delete_order) {
                    icon = "mdi-shield-lock-outline"
                    title = "VOID REQUIRED TO DELETE ORDER"
                    text = "Deleting order(s) require admin permission. Enter your admin phone and password to authorize this action."
                    dialog = this.MBS.actions.dialogConfirmPassword
                }
                dialog({
                    show:true,
                    fixed:true,
                    icon:icon,
                    title:title,
                    text:text,
                    btnYes:mbs_text.btnYes,  
                    btnNo:mbs_text.btnNo,  
                    action:{
                        code:PAGE_NAME+"=DELETE-ALL-ITEM-ORDERS",
                        data:payload, 
                        all_users:require_void_to_delete_order,
                        get_access:{
                            page_name:"Sale",
                            company_user_group_name:"Admin"
                        }
                    }
                })  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CONFIRM_DELETE_ALL_ITEM_ORDER',
                    page:PAGE_NAME, 
                }) 
            }
        }, 
        DELETE_ITEM_ORDER(payload){
            let response = {
                from:'DELETE_ITEM_ORDER',
                page:PAGE_NAME, 
                payload:payload
            }
            try {   
                let order = payload?.order
                let item_order_key = order?.key
                let day_shift_key = order?.day_shift_key
                let company_key = order?.company_key
                let created_uid = order?.created_uid
                let deleting_reason = payload?.deleting_reason
                let notify = payload?.notify
                let order_version = this.toNumber(order?.order_version )
                DB.updateItems({
                    name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values,
                    data:{
                        key:item_order_key,
                        day_shift_key:day_shift_key,
                        company_key:company_key,
                        created_uid:created_uid,
                        deleted:true,
                        deleting_reason:deleting_reason,
                        uploaded:false,
                        order_version:order_version+1,
                        update_locals:true
                    }
                }).then(res=>{  
                    this.$emit("on_deleted_item_order",res) 
                    this.MBS.events.$emit("SUBMIT_ITEM_ORDERS") 
                    this.dialog_view_order = false
                    if (notify) { 
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.ORDERS.name,"Deleting")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }
                }).catch(error=>{
                    this.MBS.actions.error({...response,error:error,no_upload:true }) 
                }) 
            } catch (error) {
                this.MBS.actions.error({...response,error:error }) 
            } 
        },
        async DELETE_MULTIPLE_ITEM_ORDERS(payload){
            let response = {
                from:'DELETE_MULTIPLE_ITEM_ORDERS',
                page:PAGE_NAME, 
                payload:payload
            }
            try {   
                let deleting_reason = payload?.deleting_reason
                let notify = payload?.notify
                let item_order = payload?.item_order
                let orders = item_order?.orders
                if(!orders){return null}
                let updating_orders = []
                orders.forEach(order => {
                    let item_order_key = order?.key
                    let day_shift_key = order?.day_shift_key
                    let company_key = order?.company_key
                    let created_uid = order?.created_uid 
                    let order_version = this.toNumber(order?.order_version )
                    updating_orders.push({
                        key:item_order_key,
                        day_shift_key:day_shift_key,
                        company_key:company_key,
                        created_uid:created_uid,
                        deleted:true,
                        deleting_reason:deleting_reason,
                        uploaded:false,
                        order_version:order_version+1,
                        update_locals:true
                    }) 
                }); 
                DB.updateItems({
                    name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values,
                    data:updating_orders
                }).then(res=>{  
                    this.$emit("on_deleted_item_order",res) 
                    this.MBS.events.$emit("SUBMIT_ITEM_ORDERS") 
                    this.dialog_view_order = false
                    if (notify) { 
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.ORDERS.name,"Deleting")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }
                }).catch(error=>{
                    this.MBS.actions.error({...response,error:error,no_upload:true }) 
                }) 
            } catch (error) {
                this.MBS.actions.error({...response,error:error }) 
            } 
        }, 
        async DELETE_ALL_ITEM_ORDER(payload){
            let response = {
                from:'DELETE_ALL_ITEM_ORDER',
                page:PAGE_NAME, 
                payload:payload
            }
            try {   
                let deleting_reason = payload?.deleting_reason
                let notify = payload?.notify
                const orders = this.AllItemOrders
                if(!orders){return null}
                let updating_orders = []
                orders.forEach(order => {
                    let item_order_key = order?.key
                    let day_shift_key = order?.day_shift_key
                    let company_key = order?.company_key
                    let created_uid = order?.created_uid 
                    let order_version = this.toNumber(order?.order_version )
                    updating_orders.push({
                        key:item_order_key,
                        day_shift_key:day_shift_key,
                        company_key:company_key,
                        created_uid:created_uid,
                        deleted:true,
                        deleting_reason:deleting_reason,
                        uploaded:false,
                        order_version:order_version+1,
                        update_locals:true
                    }) 
                }); 
                DB.updateItems({
                    name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values,
                    data:updating_orders
                }).then(res=>{  
                    this.$emit("on_deleted_item_order",res) 
                    this.MBS.events.$emit("SUBMIT_ITEM_ORDERS") 
                    this.dialog_view_order = false
                    if (notify) { 
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.ORDERS.name,"Deleting")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }
                }).catch(error=>{
                    this.MBS.actions.error({...response,error:error,no_upload:true }) 
                }) 
            } catch (error) {
                this.MBS.actions.error({...response,error:error }) 
            } 
        }, 
         
        //-------------[main]
        async DIALOG_YES(action){ 
            try {
                if (action.code) {
                    if (action.code ===PAGE_NAME+'=CONFIRM-SELECT-COMPANY') {
                        if (action.YES) {
                            setTimeout(() => { 
                                this.input.check = true 
                                this.MBS.actions.dialogInput({
                                    show:true,
                                    fixed:true,
                                    title:"CONFIRM",
                                    text:"Enter your password to confirm switching to "+this.MBS.actions.TEXT_UP(action.data.company_name)+".",
                                    btnYes:"CONFIRM",
                                    field:[
                                        {field:"password",label:'Password',type:'password'}
                                    ],
                                    action:{
                                        ...action,
                                        code:PAGE_NAME+"=SELECT-COMPANY",
                                    }
                                })  
                            }, this.MBS.data.ACTION_REFRESH_TIME); 
                        } else {
                            
                        }  
                    } 
                    if (action.code ===PAGE_NAME+'=SELECT-COMPANY') {
                        if (action.YES) {
                            setTimeout(() => { 
                                let password = action.password
                                password = password?this.MBS.crypt.encrypt(password):null 
                                if (!password) { 
                                    this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                }else if (password!==this.ud.p_uid) { 
                                    this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                } else {
                                    this.SELECT_COMPANY(action.data) 
                                }   
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        } else {
                            
                        }
                    }  
                    if (action.code ===PAGE_NAME+'=CONFIRM-CLOSE-REGISTER') {
                        if (action.YES) {
                            setTimeout(() => {   
                                this.CONFIRM_BEFORE_CLOSING()
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        } else { 
                            setTimeout(() => {   
                                this.CONFIRM_FORCE_CLOSING_REGISTER()
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        }     
                    } 
                    if (action.code ===PAGE_NAME+'=CLOSE-REGISTER') {
                        if (action.YES) {
                            this.CLOSE_REGISTER(action.data)
                        } else { 
                        }
                    }if (action.code ===PAGE_NAME+'=ASK-TO-CLOSE-REGISTER') {
                        if (action.YES) {
                            this.ON_CLOSE_REGISTER(action.data)
                            this.DELETE_COMPANY_QUICK_ACTION(action.data)
                        } else { 
                        }
                    }
                    if (action.code ===PAGE_NAME+'=FORCE-CLOSE-REGISTER') {
                        if (action.YES) {
                            await this.FORCE_CLOSING_REGISTER(action.data)  
                        } else { 
                        }
                    }
                    if (action.code ===PAGE_NAME+'=CLOSE-CASHUP') {
                        if (action.YES) {
                            this.UPLOAD_SALES(action.data)
                        } else {
                            // this.SUBMIT_CLOSE_CASHUP(action.data) 
                        }
                    }   

                      
                    if (action.code ===PAGE_NAME+'=DELETE-ITEM-ORDER') {
                        if (action.YES) {
                            setTimeout(() => {  
                                this.DELETE_ITEM_ORDER(action.data)
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        } else { 
                        }     
                    } 
                    if (action.code ===PAGE_NAME+'=DELETE-MULTIPLE-ITEM-ORDERS') {
                        if (action.YES) {
                            setTimeout(() => {  
                                this.DELETE_MULTIPLE_ITEM_ORDERS(action.data)
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        } else { 
                        }     
                    }   
                    if (action.code ===PAGE_NAME+'=DELETE-ALL-ITEM-ORDERS') {
                        if (action.YES) {
                            setTimeout(() => {  
                                this.DELETE_ALL_ITEM_ORDER(action.data)
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        } else { 
                        }     
                    } 
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'DIALOG_YES',
                    page:PAGE_NAME, 
                }) 
            }  
        },
    },
    beforeDestroy(){
        this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);    
        this.MBS.events.$off('ON_CLOSE_REGISTER', this.ON_CLOSE_REGISTER);    

        this.MBS.events.$off('CONFIRM_DELETE_ITEM_ORDER', this.CONFIRM_DELETE_ITEM_ORDER);   
        this.MBS.events.$off('CONFIRM_DELETE_ALL_ITEM_ORDER', this.CONFIRM_DELETE_ALL_ITEM_ORDER);   
        this.MBS.events.$off('DELETE_ITEM_ORDER', this.DELETE_ITEM_ORDER);   
        this.MBS.events.$off('DELETE_MULTIPLE_ITEM_ORDERS', this.DELETE_MULTIPLE_ITEM_ORDERS);   
        this.MBS.events.$off('DELETE_ALL_ITEM_ORDER', this.DELETE_ALL_ITEM_ORDER);  
    },
    watch:{  
        input(){
             
        }, 
        LocalCashup(value){
            this.CLEAR_ITEM_ORDERS(value)
        }
    }
}
</script> 